.tribe {
    position: relative;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 429px;
    left: 604px;
    cursor: pointer;
    background: rgba(215, 252, 81, 0.1);
    border: 0.589174px solid #D7FC51;
    border-radius: 100%;
    width: 74px;
    height: 74px;
}

.circle img {
    width: 4rem !important;
    height: 4rem !important;
}

.ifFalse {
    position: absolute;
    top: 215px;
    left: 48px;
    z-index: 1;
    transform: scale(0);
}
.ifTrue {
    position: absolute;
    top: 215px;
    left: 48px;
    z-index: 1;
    transform: scale(1);
}

@media (min-width:1900px) {
    .circle {
        top: 550px;
        left: 320px;
        width: 5.6rem;
        height: 5.6rem;
    }

    .circle img {
        width: 5rem !important;
        height: 5rem !important;
    }
}

@media only screen and (max-width: 1024px)  {
    .circle {
        top: 264px;
        left: 325px;
        width: 2.25rem;
        height: 2.25rem;
    }

    .circle img {
        width: 26px !important;
        height: 26px !important;
    }

    .ifFalse {
        display: none;
    }
    .ifTrue {
        display: none;
    }
}

.map {
    position: absolute;
    top: 0;
    z-index: 0;
    left: 50%;
    transform: translateX(-20%);
    content: "";
    width: 929px;
    height: 826px;
    background-image: url('../public/image/map.webp');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    margin-top: 25px;
    overflow-x: hidden;
}

@media (min-width:1900px) {
    .map {
        top: 0;
        z-index: 0;
        left: 50%;
        width: 1280px;
        height: 1000px;
        background-image: url('../public/image/map.webp');
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        margin-top: 25px;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 1024px)  {
    .map {
        left: 50%;
        transform: translateX(-50%);
        height: 527px;
        width: 500px;
    }
}
